import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Cost Optimization Levers</h2>
      <p>Aprenda algumas técnicas e diretrizes sobre como a AWS permite que você assuma o controle dos custos e otimize continuamente suas despesas, ao mesmo tempo em que cria aplicativos modernos e escaláveis para atender às suas necessidades.</p>
      <p className="video-header">Cost Optimization Levers</p>      <video src="https://d3csjjh7wiff1l.cloudfront.net/Cost_Optimization_Levers.mp4" type="video/mp4" width="1024" controls></video>
    </div>
  );
}

export default Home;