import React from 'react';

function Sub21() {
  return (
    <div>
      <h2 className="header-with-line">Acreditación Cloud Economics</h2>
      <p>Este curso ensina sobre economia de custos e economia do data center em relação à computação em nuvem. Os módulos abrangem os pilares do Cloud Value Framework, os principais conceitos e as melhores práticas de gerenciamento financeiro na nuvem e a ferramenta de avaliação de portfólio de migração (MPA). Depois de concluir todos os módulos e passar na avaliação do curso, os parceiros da Rede de Parceiros da AWS (APN) recebem a certificação AWS Cloud Economy.</p>
      <a href="https://explore.skillbuilder.aws/learn/course/view/elearning/2328/aws-partner-cloud-economics-accreditation-spanish-from-latin-america" target="_blank" rel="noopener noreferrer">
            <img src="/images/aws-partner-cloud-economics-accreditation-spanish-from-latin-america.png" alt="AWS Partner: Cloud Economics Accreditation" />
      </a>
    </div>
  );
}

export default Sub21;