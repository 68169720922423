import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">O resumo da Cloud Economics</h2>
      <p>Analisaremos um resumo do que aprendemos com a economia da nuvem no momento como um guia para nos prepararmos para o próximo conteúdo.</p>
      <p className="video-header">The Business Case for Cloud</p>
      <video src="https://d3csjjh7wiff1l.cloudfront.net/CE_Overview.mp4" type="video/mp4" width="1024" controls></video>
    </div>
  );
}

export default Home;