import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Cloud Financial Management</h2>
      <p>O gerenciamento financeiro em nuvem (CFM) é um conjunto de atividades que permite que organizações financeiras e de tecnologia gerenciem, otimizem e planejem custos na AWS. Uma abordagem disciplinada do CFM permite que os clientes encontrem o equilíbrio certo entre custo, agilidade e controle, ao mesmo tempo em que melhoram os custos unitários. Embora as economias potenciais derivadas da migração para a nuvem possam ser altas, as economias reais são subjetivas e baseadas na estratégia de CFM do cliente.</p>
      <p>Nesta seção, você encontrará recursos não apenas para fortalecer a prática de valor comercial da CE que você acabou de aprender, mas também para iniciar a próxima etapa natural para nossos clientes, como o Cloud Financial Management, que nos ajudará a orientá-los sobre a melhor maneira de gerenciar, otimizar e planejar custos na AWS. Além disso, você encontrará recursos de melhores práticas de nossos especialistas para continuar ajudando você a fortalecer a prática consultiva de sua organização.</p>
      <p>Treinamento digital para parceiros da AWS:</p>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>1. </p>
        <a href="https://kiku.aws.training/SessionSearch?pageNumber=1&courseId=23615&languageId=1" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>AWS Partner: Migrating to AWS (Technical)</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>2. </p>
        <a href="https://kiku.aws.training/SessionSearch?pageNumber=1&courseId=16351&languageId=1" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>AWS Partner: Migrating to AWS (Business) (Classroom)</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>3. </p>
        <a href="https://explore.skillbuilder.aws/learn/course/internal/view/elearning/12954/aws-cloud-economics-for-startups" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>Cloud Economics for Startups</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>4. </p>
        <a href="https://attendee.gotowebinar.com/recording/3931553841237795587" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>Actualización de Incentivos del Programa de Aceleración de Migraciones (MAP)</a>
      </div>
      {/* Add more content as needed */}
    </div>
  );
}

export default Home;
