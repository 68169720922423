import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Importancia de las Finanzas</h2>
      <p>Você aprenderá o que é importante ouvir para os CXOs, as métricas financeiras mais importantes para análise de projetos e tomada de decisões.</p>
      <p className="video-header">What does de CxOs care about?</p>      <video src="https://d3csjjh7wiff1l.cloudfront.net/BCCP_Partner_Program.mp4" type="video/mp4" width="1024" controls></video>
    </div>
  );
}

export default Home;