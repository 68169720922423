import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Migration Acceleration Program</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a posuere dui. Vestibulum tincidunt leo sit amet libero convallis viverra. Ut turpis magna, varius at turpis ut, fringilla ultricies tellus. Nunc at dapibus tortor. Integer nec molestie risus. Mauris placerat egestas nunc quis pulvinar. Sed id turpis consectetur, facilisis mauris at, ultrices enim. Vestibulum rhoncus gravida augue et feugiat. Morbi finibus elementum lectus at interdum</p>
      <p>Recursos:</p>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>1. </p>
        <a href="https://aws.amazon.com/migration-acceleration-program/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>AWS Migration Acceleration Program (MAP)</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>2. </p>
        <a href="https://partnercentral.awspartner.com/apex/Resources?sfdc.tabName=01r8a000001A846&Id=0698a00000FIgBmAAL" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>Visão Geral Migration Acceleration Program </a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>3. </p>
        <a href="https://partnercentral.awspartner.com/apex/Resources?sfdc.tabName=01r8a000001A846&Id=0698a00000CY8WWAA1" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>Migration Acceleration Program (MAP) First Call Deck</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>4. </p>
        <a href="https://partnercentral.awspartner.com/apex/Resources?sfdc.tabName=01r8a000001A846&Id=0698a00000DhfjQAAR" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>Deck para jornada do AWS Partner MAP</a>
      </div>
      <p className="video-header">MAP Tagging video</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://player.vimeo.com/video/783696642?h=220015d3fd" // Replace YOUR_VIDEO_ID with the actual Vimeo video ID
          title="Vimeo Video"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Home;