import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Home({ signOut, user }) {
  return (
    <div>
      <h2 className="header-with-line">Descrição do Programa</h2>
      <p>O programa de treinamento em economia da nuvem (CE) foi projetado para explicar a você a prática usada pela equipe da AWS na América Latina para quantificar o valor comercial da transferência de cargas de trabalho para a AWS. Como você bem sabe, o CE é composto por dois componentes: Business Value (BV) e Cloud Financial Management (CFM). Este curso inicialmente se concentra no BV e fornece uma introdução ao CFM. Aqui você aprenderá os diferentes tipos de caso de negócios, as ferramentas necessárias para construí-lo e as alavancas de otimização de custos para apresentar a melhor oferta econômica e eficiente para o cliente. Você também terá a oportunidade de praticar um caso de negócios supervisionado pela equipe de CE.</p>
      <p>O programa abrange uma ampla variedade de tópicos, desde as melhores práticas para calcular casos de negócios de migração até ferramentas e modelos da AWS e programas de investimento da AWS, até responder às perguntas mais desafiadoras dos clientes. O programa oferece acesso a conteúdo sob demanda criado pela equipe da CE LatAm e uma sessão prática equivalente a 16 horas de treinamento em 4 semanas.</p>
      <p className="video-header">Apresentação do programa Cloud Economics</p>
      <div className="iframe-container">
        <iframe 
          src="/LatAmCloudEconomicsPartnerTraining.pdf" 
          title="Your Iframe Title"
          width="100%"
          height="600px"
        ></iframe>
      </div>
      <p className="video-header">AWS Cloud Value Framework</p>
      {/* Here's the iframe for the YouTube video */}
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/dySOcgIg77E" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Forrester Explains the Total Economic Impact™ of Working with AWS Customer Enablement Services</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/SkTRQ78DW0g" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default withAuthenticator(Home);
