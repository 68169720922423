import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Cloud Financial Onboarding</h2>
      <p>Cloud Finance Onboarding (CFO) é uma série de vídeos voltados principalmente para profissionais de finanças, compras e pessoal não tecnológico, projetados para aprender os fundamentos do gerenciamento financeiro na nuvem: alocação, otimização, previsão e orçamento de gastos na nuvem; além de implementar uma função FinOps em sua organização. Esse treinamento também ajuda as organizações a dedicar tempo ao aprofundamento da compreensão do papel de cada uma das partes interessadas (finanças, tecnologia, FinOps) em seu processo de gerenciamento financeiro na nuvem.</p>
      <p>Este conteúdo foi criado para educar as áreas financeiras ou qualquer outra parte interessada a aprender os fundamentos do CFM/FonOps.</p>
      <p className="video-header">Cloud Visibility & Accountability</p>      
      <video src="https://d3csjjh7wiff1l.cloudfront.net/CFO-Module1.mp4" type="video/mp4" width="1024" controls></video>
      <p className="video-header">Cloud Cost Optimisation</p>      
      <video src="https://d3csjjh7wiff1l.cloudfront.net/CFO-Module2.mp4" type="video/mp4" width="1024" controls></video>
      <p className="video-header">Cloud Financial Predictability Breakfast</p>      
      <video src="https://d3csjjh7wiff1l.cloudfront.net/CFO-Module3.mp4" type="video/mp4" width="1024" controls></video>
      <p className="video-header">Cloud Financial Operations</p>      
      <video src="https://d3csjjh7wiff1l.cloudfront.net/CFO-Module4.mp4" type="video/mp4" width="1024" controls></video>
      <p className="video-header">Cloud Performance Indicators (KPIs)</p>      
      <video src="https://d3csjjh7wiff1l.cloudfront.net/CFO-Module5.mp4" type="video/mp4" width="1024" controls></video>
    </div>
  );
}

export default Home;