import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Exercício de um caso de negócios</h2>
      <p>Este é o último ponto do treinamento de parceiros da Cloud Economics. Nesta seção, praticaremos a criação de um caso de negócios, usando todas as informações que aprendemos ao longo desse tempo. É importante que você trabalhe junto com sua equipe de arquitetura e/ou serviços para criar uma proposta que combine a experiência da sua empresa com a prática que você aprendeu durante esse período. Não há resposta certa ou errada, lembre-se de que, com um cliente real, você apresentaria uma solução e provavelmente sua concorrência outra. Use os modelos a seguir para realizar este exercício e, em caso de dúvida, consulte seu PDM para qualquer esclarecimento.</p>
      <p>Arquivos a serem usados:</p>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>1. Leia o arquivo com atenção:</p>
        <a href="https://d3csjjh7wiff1l.cloudfront.net/LatAm_Case_Study-es.pdf" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>LatAm Case Study-es</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>2. Use o arquivo</p>
        <a href="https://d3csjjh7wiff1l.cloudfront.net/AWS_LatAm_CEPT_Case_Study_-_AnyCompany_ABC_-_Discovery_data.xlsx" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>AWS LatAm CEPT Case Study - AnyCompany ABC - Discovery data</a>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>, para realizar o dimensionamento que você considera.</p>
      </div>
      <div>
        <p>3. Para a camada de apresentação do cliente, use os seguintes modelos (se você tiver seus próprios, sinta-se à vontade para usá-los).</p>
        <a href="https://d3csjjh7wiff1l.cloudfront.net/AWS_LatAm_CEPT_PPT_Summary.pptx" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '30px' }} >- AWS LatAm CEPT PPT Summary</a>
        <p style={{ marginLeft: '30px'}}>- Cash-Flow Template</p>
      </div>
    </div>
  );
}

export default Home;