import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Investimentos estratégicos</h2>
      <p>Programa de aceleração da migração.</p>
      <p>Programa de descontos executivos.</p>
      <p className="video-header">Migration Acceleration Program - Part 1</p>
      <video src="https://d3csjjh7wiff1l.cloudfront.net/20230228-MAPCECONPartnertraining-part1.mp4" type="video/mp4" width="1024" controls></video>
      <p className="video-header">Migration Acceleration Program - Part 2</p>
      <video src="https://d3csjjh7wiff1l.cloudfront.net/20230228-MAPCECONPartnertraining-part2.mp4" type="video/mp4" width="1024" controls></video>
    </div>
  );
}

export default Home;