import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Cloud Financial Management</h2>
      <p>[..]</p>
    </div>
  );
}

export default Home;