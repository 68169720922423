import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Cloud Financial Management</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a posuere dui. Vestibulum tincidunt leo sit amet libero convallis viverra. Ut turpis magna, varius at turpis ut, fringilla ultricies tellus. Nunc at dapibus tortor. Integer nec molestie risus. Mauris placerat egestas nunc quis pulvinar. Sed id turpis consectetur, facilisis mauris at, ultrices enim. Vestibulum rhoncus gravida augue et feugiat. Morbi finibus elementum lectus at interdum</p>
      <p className="video-header">Introdução ao  Cloud Financial Management</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/cSnBC2MqqUE" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Coloque seu custo de nuvem no caminho certo</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/W5-BT25bk3U" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Gerencie e controle seus custos da AWS</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/vWq90UMQQGI" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Otimize o custo e o uso da AWS com Cloud Intelligence Dashboards</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/BNYhk_wm4no" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Simplifique sua estimativa de custo da AWS</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/lGWyBdKeplQ" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Revelando o potencial do AWS Cost Explorer</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/UfPqrkoUFiQ" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Home;