import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Tools</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a posuere dui. Vestibulum tincidunt leo sit amet libero convallis viverra. Ut turpis magna, varius at turpis ut, fringilla ultricies tellus. Nunc at dapibus tortor. Integer nec molestie risus. Mauris placerat egestas nunc quis pulvinar. Sed id turpis consectetur, facilisis mauris at, ultrices enim. Vestibulum rhoncus gravida augue et feugiat. Morbi finibus elementum lectus at interdum</p>
      <p className="video-header">Migration Evaluator: Passo a passo de instalação do coletor sem agente</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/Ez5n6I7NOo4" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Rastreie rapidamente seu business case para a AWS com o Migration Evaluator</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/Q1UcuaJQnSE" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">TCO analise com os serviços AWS e o  Migration Portfolio Assessment</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/2qautbhuJC8" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Mapeamento de Dependência do Servidor usando Migration Evaluator | Amazon Web Services</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/uQlmdePrgR0" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Como criar um Business Case de migração atraente usando o  TSO Logic - AWS Online Tech Talks</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/QBLVRLhkJAQ" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Migre aplicações para a nuvem com  CloudEndure Migration</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/Joce88i_1Ts" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">A criação de uma máquina de migração bem alinhada</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/lpnnCZLgYvQ" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">Entregando um OLA com o Migration Evaluator</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/6xkKgdcVou8" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">AWS Partner: Migrating to AWS (Technical) (Portuguese)</p>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}></p>
        <a href="https://explore.skillbuilder.aws/learn/course/internal/view/elearning/2545/aws-partner-migrating-to-aws-technical-portuguese" target="_blank" rel="noopener noreferrer">
            <img src="/images/Migrating-to-AWS-portuguese.png" alt="AWS Partner: Migrating to AWS (Technical) (Portuguese)" />
        </a>
      </div>
    </div>
  );
}

export default Home;