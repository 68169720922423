import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Business Value</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a posuere dui. Vestibulum tincidunt leo sit amet libero convallis viverra. Ut turpis magna, varius at turpis ut, fringilla ultricies tellus. Nunc at dapibus tortor. Integer nec molestie risus. Mauris placerat egestas nunc quis pulvinar. Sed id turpis consectetur, facilisis mauris at, ultrices enim. Vestibulum rhoncus gravida augue et feugiat. Morbi finibus elementum lectus at interdum</p>
      <p className="video-header">Que valor comercial eu posso esperar alcançar usando a AWS?</p>
      <div className="iframe-container" z-index="1000">
        <iframe 
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/HCElRXgoZJU" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowfullscreen
        ></iframe>
      </div>
      <p className="video-header">Melhores práticas para uma migração bem-sucedida na América Latina</p>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="600px"
          src="https://www.youtube.com/embed/DDSdDhocv7I" // Replace VIDEO_ID with the actual ID of the YouTube video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p className="video-header">AWS Cloud Financial Management Blog</p>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}></p>
        <a href="https://aws.amazon.com/blogs/aws-cloud-financial-management/five-things-you-should-do-to-create-an-accurate-on-premises-vs-cloud-comparison-model/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>5 coisas que você deve fazer para criar um modelo de comparação On-premises x Cloud bem-sucedido</a>
      </div>
      <p className="video-header">Whitepapers</p>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>1. </p>
        <a href="https://pages.awscloud.com/global-in-gc-500-business-value-of-migration-whitepaper-learn.html?gc-language=es-xl" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>O valor comercial da migração para a AWS</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>2. </p>
        <a href="https://pages.awscloud.com/rs/112-TZM-766/images/The%20Business%20Value%20of%20Adopting%20Amazon%20Web%20Services%20Managed%20Databases.pdf" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>O valor comercial da adoção de bancos de dados gerenciados da Amazon Web Services (AWS)</a>
      </div>
      <div>
        <p style={{ display: 'inline-block', marginRight: '5px' }}>3. </p>
        <a href="https://pages.awscloud.com/rs/112-TZM-766/images/known-business-value-of-cloud-%20modernization-012022.pdf" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>Valor comercial da modernização da nuvem</a>
      </div>
    </div>
  );
}

export default Home;