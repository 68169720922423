import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Herramientas</h2>
      <p>Essa é uma descrição de alto nível de como e quando usar o Migration Evaluator e o Migration Portfolio Assessment para apoiar um caso de negócios.</p>
      <p className="video-header">Migration Portfolio Assessment</p>
      <video src="https://d3csjjh7wiff1l.cloudfront.net/MPA_for_Business_Case.mp4" type="video/mp4" width="1024" controls></video>
    </div>
  );
}

export default Home;