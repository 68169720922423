import React from 'react';

function Sub21() {
  return (
    <div>
      <h2 className="header-with-line">AWS Partner Migration Business Case</h2>
      <p>Neste curso, você aprenderá as melhores práticas não técnicas para migrar cargas de trabalho em grande escala para a nuvem da AWS. Você também discutirá os três tipos de análise que resumem os argumentos comerciais para a migração para a AWS.</p>
      <a href="https://explore.skillbuilder.aws/learn/course/view/elearning/12799/aws-partner-migration-business-case-spanish-from-latin-america" target="_blank" rel="noopener noreferrer">
            <img src="/images/aws-partner-migration-business-case.png" alt="AWS Partner: Cloud Economics Accreditation" />
      </a>
    </div>
  );
}

export default Sub21;