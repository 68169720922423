import { Amplify } from 'aws-amplify';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import './App.css';
import './Menu.css';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';

// Import your page components
import Home from './pages/Home';
import Page20 from './pages/menu2/Page20';
import Sub21 from './pages/menu2/Sub21';
import Sub22 from './pages/menu2/Sub22';
import Sub23 from './pages/menu2/Sub23';
import Sub24 from './pages/menu2/Sub24';
import Sub25 from './pages/menu2/Sub25';
import Sub26 from './pages/menu2/Sub26';
import Sub27 from './pages/menu2/Sub27';
import Sub28 from './pages/menu2/Sub28';
import Sub29 from './pages/menu2/Sub29';
import Page30 from './pages/menu3/Page30';
import Sub31 from './pages/menu3/Sub31';
import Sub32 from './pages/menu3/Sub32';
import Page40 from './pages/menu4/Page40';
import Sub41 from './pages/menu4/Sub41';
import Sub42 from './pages/menu4/Sub42';
import Sub43 from './pages/menu4/Sub43';
import Sub44 from './pages/menu4/Sub44';

Amplify.configure(awsExports);

function App({ signOut, user }) {
  return (
    <Router>
      <div className="App">
        {/* Upper Banner */}
        <div className="upper-banner">
          <img style={{ display: 'inline-block' }} className="banner-image" src="images/aws-banner-logo.png" alt="Description" />
          <p className="banner-top-text" style={{ display: 'inline-block' }}>Cloud Economics</p>
          <div className="logout-link" style={{ display: 'inline-block' }} onClick={signOut}>
            Sign Out
          </div>


        </div>
        
        {/* Bottom banner */}
        <div className="bottom-banner">
          {/* Content of the banner, if any */}
        </div>
        {/* Main Content including Left Panel */}
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '60px' }}>
          {/* Left Menu Panel */}
          <div className="menu-panel">
            {/* Home Section */}
            <div className="menu-section">
              <NavLink to="/" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>
                1. Descrição do Programa
              </NavLink>
            </div>
            {/* Section 2 */}
            <div className="menu-section">
              <NavLink to="/page20" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2. Business Value</NavLink>
              {/* Sub-menus for Main Section 2 */}
              <div className="sub-menu">
                <NavLink to="/sub21" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.1 Credenciamento Cloud Economics</NavLink>
                {/* Add other sub-menu items as needed */}
              </div>
              <div className="sub-menu">
                <NavLink to="/sub22" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.2 AWS Partner Migration Business Case</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub23" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.3 O resumo da Cloud Economics</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub24" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.4 Importância das finanças</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub25" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.5 Cost Optimization Levers</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub26" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.6 Cloud Economics Tips & Tricks</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub27" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.7 Ferramentas</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub28" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.8 Investimentos estratégicos</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub29" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>2.9 Exercício de um caso de negócios</NavLink>
              </div>
            </div>
            {/* Section 3 */}
            <div className="menu-section">
              <NavLink to="/page30" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>3. Cloud Financial Management</NavLink>
              <div className="sub-menu">
                <NavLink to="/sub31" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>3.1 Cloud Financial Management</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub32" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>3.2 Cloud Financial Onboarding</NavLink>
              </div>
            </div>
            {/* Section 4 */}
            <div className="menu-section">
              <NavLink to="/page40" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>4. Recursos adicionais</NavLink>
              <div className="sub-menu">
                <NavLink to="/sub41" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>4.1 Business Value</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub42" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>4.2 Cloud Financial Management</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub43" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>4.3 Migration Acceleration Program</NavLink>
              </div>
              <div className="sub-menu">
                <NavLink to="/sub44" className={({ isActive }) => isActive ? "active-menu-item" : "menu-item"}>4.4 Ferramentas</NavLink>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="Page20" element={<Page20 />} />
              <Route path="Sub21" element={<Sub21 />} />
              <Route path="Sub22" element={<Sub22 />} />
              <Route path="Sub23" element={<Sub23 />} />
              <Route path="Sub24" element={<Sub24 />} />
              <Route path="Sub25" element={<Sub25 />} />
              <Route path="Sub26" element={<Sub26 />} />
              <Route path="Sub27" element={<Sub27 />} />
              <Route path="Sub28" element={<Sub28 />} />
              <Route path="Sub29" element={<Sub29 />} />
              <Route path="Page30" element={<Page30 />} />
              <Route path="Sub31" element={<Sub31 />} />
              <Route path="Sub32" element={<Sub32 />} />
              <Route path="Page40" element={<Page40 />} />
              <Route path="Sub41" element={<Sub41 />} />
              <Route path="Sub42" element={<Sub42 />} />
              <Route path="Sub43" element={<Sub43 />} />
              <Route path="Sub44" element={<Sub44 />} />
              {/* Define routes for other pages and sub-menu items */}
            </Routes>
          </div>
        </div>
      </div>
    </Router>

  );
}

export default withAuthenticator(App);