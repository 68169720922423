import React from 'react';

function Home() {
  return (
    <div>
      <h2 className="header-with-line">Cloud Economics Tips & Tricks</h2>
      <p>Neste curso, analisaremos as melhores práticas que a Cloud Economics LatAm compilou.</p>
      <p className="video-header">Cloud Economics Tips & Tricks</p>
      <video src="https://d3csjjh7wiff1l.cloudfront.net/TipsTricks.mp4" type="video/mp4" width="1024" controls></video>
    </div>
  );
}

export default Home;